
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Satoshi', sans-serif;
  font-weight: 400;
}

:root {
  --primary: rgba(34, 68, 129, 1);
  --white: #ffffff;
  --gray: rgba(130, 130, 130, 1);
  --blue-light: rgba(5, 135, 242, 1);
  --dashboard-gray: rgba(242, 242, 242, 1);
  --bg-yellow-light: #FFF7F7;
  --gray-light: #F2F2F2;
  --green: #219653;
  --red: #EB5757;
  --gray-dark: #4F4F4F;
  --yellow: #F2C94C;
  --orange: #F2994A;
  --cyan: #16A5B6;
}


html {
  background-color:var(--bg-yellow-light);
  width: 100vw;
  overflow-x: hidden;
}

li{
  list-style: none;
}

.satoshi-600 {
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;
}
.satoshi-900 {
  font-family: 'Satoshi', sans-serif;
  font-weight: 900;
}

.satoshi-500 {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
}

.animateMenu {
  background: var(--primary);
  animation: animatemenu 0.8s linear;
}

@keyframes animatemenu {
  0% {
    top: -10%;
    opacity: 0;
  }

  50% {
    top: -5%;
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}
.menu-open{
  animation: openMenu 0.4s linear;
  transform: translate(0%,-30%);
}
@keyframes openMenu {
  0% {
transform: translate(100%, -130%);
  }

  50% {
    transform: translate(50%, -80%);
  }

  100% {
  transform: translate(0%, -30%);
  }
}

.close-menu{
animation: closeMenu 0.5s linear;
transform: translate(-400%, -30%);
}


@keyframes closeMenu {
  0% {
  transform: translate(0%, -30%);
  }

  50% {
    transform: translate(80%, -30%);
  }

  100% {
    transform: translate(160%, -30%);
  }
}





.header{
  background-image: url("./Assets/Vector.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-size: 82%;
}

.svg{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -5;
  top: 0%;
  left: 100%;
  transform: translate(-93%,-0%);
}


@media screen and (max-width:1200px){
  .header{
  background-position: 100% 0%;
    background-size: 80;
  }
}

@media screen and (max-width:900px) {
  .header {
    background-position: 100% 0%;
    background-size: 70%;
  }
}